import "./style.scss";

import { Link } from "react-router-dom";
import { Stage } from "../types";
import { selectPointsForm } from "../plurals";

interface StagesProps {
  stage: Stage;
  onOpenQuiz(): void;
}

const Stages = ({ stage, onOpenQuiz }: StagesProps) => {
  return (
    <main className="stages">
      <section className="stage">
        <p className="stage_tour">МедКвиз СибГМУ</p>
        <h2 className="stage_title">{stage.title}</h2>
        {stage.canParticipate && stage.answerAvailable && (
          <button
            className="stage_button -take"
            onClick={onOpenQuiz}
            type="button"
          >
            Участвовать
          </button>
        )}
        {typeof stage.points === `number` && stage.points > 0 && (
          <p className="stage_result">
            Ваш результат: {stage.points} {selectPointsForm(stage.points)}
          </p>
        )}
        <Link className="stage_button -ratings" to="/results/stage">
          Таблица лидеров
        </Link>
      </section>
      <footer className="stages_footer">
        <ul className="links">
          <li>
            <Link to="/rules">Правила</Link>
          </li>
          <li>
            <a href="/regulations.pdf">Регламент</a>
          </li>
        </ul>
      </footer>
    </main>
  );
};

export default Stages;
