const pluralRules = new Intl.PluralRules(`ru`);
const selectForm = (number: number, forms: typeof pointsForms): string =>
  forms[pluralRules.select(number) as keyof typeof pointsForms];

const pointsForms = {
  one: `балл`,
  few: `балла`,
  many: `баллов`,
};
export const selectPointsForm = (points: number): string =>
  selectForm(points, pointsForms);

const secondsForms = {
  one: `секунду`,
  few: `секунды`,
  many: `секунд`,
};
export const selectSecondsForm = (seconds: number): string =>
  selectForm(seconds, secondsForms);
