import "./style.scss";

import useRequest from "../requests";
import Rating, { Record } from "./Rating";
import { SubmittedAnswer } from "../types";
import { selectPointsForm } from "../plurals";
import classNames from "classnames";

export interface UserResult {
  points: number;
  time: number;
  answers: SubmittedAnswer[];
}

interface QuizResultsProps {
  type: `quiz`;
  myResult: UserResult;
  maxPoints: number;
}

interface DuelResultsProps {
  type: `duel`;
  myResult: UserResult;
  mateName: string;
  mateResult: UserResult | null;
  maxPoints: number;
}

interface ShowResultsProps {
  type: `show`;
}

type StageResultsProps = { userId: number, title: string } & (
  | QuizResultsProps
  | DuelResultsProps
  | ShowResultsProps
);

interface OverallResultsProps {
  userId: number;
}

const DUEL_OUTCOMES = {
  draw: `Ничья`,
  me: `Победа`,
  mate: `Поражение`,
};

export const StageResults = (props: StageResultsProps) => {
  const rating: Record[] | null = useRequest({
    method: `GET`,
    endPoint: `Quiz/Results`,
  });

  const finalists = rating && rating.length > 0 ? rating.slice(0, 20) : null;
  const participants = rating && rating.length > 20 ? rating.slice(20) : null;

  let duelWinner: keyof typeof DUEL_OUTCOMES | null = null;
  if (props.type === `duel` && props.mateResult !== null) {
    if (props.mateResult.time === 0) {
      // противник отключился
      duelWinner = `draw`;
    } else if (props.myResult.points > props.mateResult.points) {
      duelWinner = `me`;
    } else if (props.myResult.points < props.mateResult.points) {
      duelWinner = `mate`;
    } else if (props.myResult.time < props.mateResult.time) {
      duelWinner = `me`;
    } else if (props.myResult.time > props.mateResult.time) {
      duelWinner = `mate`;
    } else {
      duelWinner = `draw`;
    }
  }

  let duelOutcome = duelWinner && DUEL_OUTCOMES[duelWinner];

  return (
    <main className="results">
      <h1 className="results_title">{props.title}</h1>
      {props.type === `quiz` && (rating?.length ?? 0) === 0 && (
        <div className="userResults">
          <h2 className="userResults_title">
            Вы сможете узнать результаты этапа после его завершения
          </h2>
        </div>
      )}
      {props.type === `duel` && (
        <div
          className={classNames(`duelResult`, {
            "-isEnded": duelWinner !== null,
          })}
        >
          {duelOutcome && <h2 className="duelResult_outcome">{duelOutcome}</h2>}
          <DuelParticipant
            name="Вы"
            type="me"
            winner={duelWinner}
            maxPoints={props.maxPoints}
            result={props.myResult}
          />
          <div className="duelResult_versus" />
          <DuelParticipant
            name={props.mateName}
            type="mate"
            winner={duelWinner}
            maxPoints={props.maxPoints}
            result={props.mateResult}
          />
        </div>
      )}
      {finalists && (
        <Rating
          records={finalists}
          title="Финалисты"
          hasBonus={true}
          userId={props.userId}
        />
      )}
      {participants && (
        <Rating
          records={participants}
          title="Участники"
          startPlace={21}
          userId={props.userId}
        />
      )}
    </main>
  );
};

interface DuelParticipantProps {
  name: string;
  type: `me` | `mate`;
  winner: keyof typeof DUEL_OUTCOMES | null;
  maxPoints: number;
  result: UserResult | null;
}

const DuelParticipant = ({
  name,
  type,
  winner,
  maxPoints,
  result,
}: DuelParticipantProps) => {
  if (result === null) {
    return (
      <div className={`duelParticipant -${type}`}>
        <h3 className="duelParticipant_name">{name}</h3>
        <p className="duelParticipant_stillAnswering">Ещё не закончил</p>
      </div>
    );
  }

  const minutes = String(Math.floor(result.time / 60)).padStart(2, `0`);
  const seconds = String(Math.round(result.time % 60)).padStart(2, `0`);

  return (
    <div
      className={classNames(`duelParticipant`, `-${type}`, {
        "-isWinner": winner === type,
      })}
    >
      <h3 className="duelParticipant_name">{name}</h3>
      <p className="duelParticipant_points">
        <span className="duelParticipant_pointsNumber">{result.points}</span>
        {` ${selectPointsForm(result.points)}`}
      </p>
      <p className="duelParticipant_answers">
        {result.points} из {maxPoints} ответов
        <br />
        за {minutes}:{seconds}
      </p>
    </div>
  );
};

export const OverallResults = ({ userId }: OverallResultsProps) => {
  const finalists: Record[] | null = useRequest({
    method: `GET`,
    endPoint: `Quiz/Results/Overall`,
  });

  return (
    <main className="results">
      <h1 className="results_title">Общие результаты</h1>
      {finalists && (
        <Rating
          records={finalists}
          title="Финалисты"
          hasBonus={true}
          userId={userId}
        />
      )}
    </main>
  );
};
