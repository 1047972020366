import { useEffect, useState } from "react";

interface UseTimeProps {
  updateEachFrame?: boolean;
}

export const useTime = ({ updateEachFrame = false }: UseTimeProps = {}) => {
  const [time, setTime] = useState(Date.now);

  useEffect(() => {
    if (updateEachFrame) {
      let requestId: number;
      const callback = () => {
        setTime(Date.now);
        requestId = requestAnimationFrame(callback);
      };

      requestId = requestAnimationFrame(callback);
      return () => cancelAnimationFrame(requestId);
    }

    const interval = setInterval(() => setTime(Date.now), 1000);
    return () => clearInterval(interval);
  }, [updateEachFrame]);

  return time;
};
