import "./style.scss";
import { Switch, Route, useHistory } from "react-router-dom";

import { Question, Stage, User } from "../types";
import { makeRequest } from "../requests";
import Quiz from "../Quiz";
import { useCallback, useEffect, useState } from "react";
import { StageResults, OverallResults, UserResult } from "../Results";
import Stages from "../Stages";
import Rules from "../Rules";
import AskQuestion from "../AskQuestion";
import Duel from "../Duel";
import Introduction from "../Introduction";
import { stageDestination } from "../util";

const App = () => {
  const history = useHistory();
  const [user, setUser] = useState<User | null>(null);
  const [stage, setStage] = useState<Stage | null>(null);
  const [questions, setQuestions] = useState<Question[] | null>(null);
  const [userResult, setUserResult] = useState<UserResult | null>(null);

  useEffect(() => {
    let isCancelled = false;

    makeRequest<User>({ method: `GET`, endPoint: `Account` }).then((user) => {
      if (!isCancelled) {
        setUser(user!);
      }
    });

    makeRequest<Stage>({
      method: `GET`,
      endPoint: `Quiz/Stage`,
    }).then((stage) => {
      if (!isCancelled) {
        setStage(stage!);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  const handleSubmit = useCallback(
    async (userResult: UserResult) => {
      setUserResult(userResult);
      setStage((stage) => ({ ...stage!, canParticipate: false }));
      history.replace(`/results/stage`);

      await makeRequest({
        method: `POST`,
        endPoint: `Quiz/Results`,
        parameters: userResult,
      });
    },
    [history],
  );

  const closeAskQuestion = useCallback(() => {
    setStage((stage) => ({ ...stage!, canParticipate: false }));
    history.replace(`/`);
  }, [history]);

  const openQuiz = useCallback(async () => {
    if (!stage || !stage.isActive || !stage.canParticipate) {
      return;
    }

    const allQuestions = await makeRequest<Question[]>({
      method: `GET`,
      endPoint: `Quiz/Questions`,
    });
    const questions = allQuestions!.filter((question) => question.isActive);
    setQuestions(questions);

    const destination = stage.showStartView ? `/introduction` : stageDestination(stage);
    history.push(destination);
  }, [stage, history]);

  if (!stage) {
    return null;
  }

  return (
    <Switch>
      <Route exact path="/">
        <Stages stage={stage} onOpenQuiz={openQuiz} />
      </Route>
      <Route exact path="/introduction">
        <Introduction stage={stage} />
      </Route>
      <Route
        exact
        path="/quiz"
        render={() => <Quiz questions={questions!} onSubmit={handleSubmit} />}
      />
      <Route
        exact
        path="/duel"
        render={() => (
          <Duel
            questions={questions!}
            id={user!.id}
            name={`${user!.firstName} ${user!.lastName}`}
            title={stage.title}
          />
        )}
      />
      <Route
        exact
        path="/results/stage"
        render={() =>
          userResult ? (
            <StageResults
              type="quiz"
              title={stage.title}
              myResult={userResult}
              maxPoints={questions!.length}
              userId={user!.id}
            />
          ) : (
            <StageResults type="show" title={stage.title} userId={user!.id} />
          )
        }
      />
      <Route
        exact
        path="/results/overall"
        render={() => <OverallResults userId={user!.id} />}
      />
      <Route exact path="/rules">
        <Rules />
      </Route>
      <Route exact path="/askQuestion">
        {questions && (
          <AskQuestion
            questionId={questions[0].id}
            onClose={closeAskQuestion}
          />
        )}
      </Route>
    </Switch>
  );
};

export default App;
