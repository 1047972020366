import "./style.scss";

import { useCallback, useMemo, useState } from "react";
import Question from "../Question";
import * as types from "../types";
import { ReactComponent as ArrowIcon } from "./icons/arrow.svg";
import { ReactComponent as CheckIcon } from "./icons/check.svg";
import classNames from "classnames";
import calculatePoints from "./calculatePoints";
import { makeRequest } from "../requests";
import { UserResult } from "../Results";

interface QuizProps {
  questions: types.Question[];
  onSubmit(userResult: UserResult): void;
}

export type Answer = string | number | Set<number>;

const getDefaultAnswer = (question: types.Question): Answer | null => {
  switch (question.type) {
    case types.QuestionType.STRING:
      return ``;
    case types.QuestionType.SINGLE:
      return null;
    case types.QuestionType.MULTIPLE:
      return new Set();
    case types.QuestionType.FREE_FORM:
      return ``;
  }
};

const isEmptyAnswer = (answer: Answer | null): boolean =>
  answer === null ||
  (typeof answer === `string` && answer.trim() === ``) ||
  (answer instanceof Set && answer.size === 0);

const Quiz = ({ questions, onSubmit }: QuizProps) => {
  const startTime = useMemo(() => Date.now(), []);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [answers, setAnswers] = useState<Answer[]>([]);
  const [answer, setAnswer] = useState<Answer | null>(() =>
    getDefaultAnswer(questions[0]),
  );
  const handleSubmit = useCallback(async () => {
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = answer!;
    setAnswers(newAnswers);

    const nextQuestionIndex = currentQuestionIndex + 1;
    if (nextQuestionIndex < questions.length) {
      setAnswer(
        answers[nextQuestionIndex] ??
          getDefaultAnswer(questions[nextQuestionIndex]),
      );
      setCurrentQuestionIndex(nextQuestionIndex);
      return;
    }

    const points = calculatePoints(questions, answers);
    const time = (Date.now() - startTime) / 1000;
    const freeFormAnswers = questions.reduce((answers, question, index) => {
      if (question.type !== types.QuestionType.FREE_FORM) {
        return answers;
      }

      answers.push({
        questionId: question.id,
        answer: newAnswers[index] as string,
      });
      return answers;
    }, [] as types.SubmittedAnswer[]);
    const userResult = { points, time, answers: freeFormAnswers };
    onSubmit(userResult);
  }, [startTime, questions, onSubmit, answers, answer, currentQuestionIndex]);

  const question = questions[currentQuestionIndex];
  const isLastQustion = currentQuestionIndex === questions.length - 1;

  const maxDots = Math.floor(
    (window.innerWidth - (20 + 55 + 12) * 2 + 8) / (12 + 8),
  );
  const dotsAmount = Math.min(maxDots, questions.length);
  const middleDot = Math.ceil(dotsAmount / 2);
  let skipDots = Math.max(0, currentQuestionIndex - middleDot + 1);
  if (skipDots + dotsAmount > questions.length) {
    skipDots = questions.length - dotsAmount;
  }

  return (
    <main className="quiz">
      <Question
        key={currentQuestionIndex}
        answer={answer}
        question={question}
        onSubmit={handleSubmit}
        onChange={setAnswer}
        currentQuestion={currentQuestionIndex}
        totalQuestions={questions.length}
      />
      <div className="progress">
        <button
          type="button"
          className="progress_goButton -back"
          disabled={currentQuestionIndex === 0 || `timeout` in questions[0]}
          onClick={() => {
            const previousQuestionIndex = currentQuestionIndex - 1;
            setCurrentQuestionIndex(previousQuestionIndex);
            setAnswer(answers[previousQuestionIndex]);
          }}
        >
          <ArrowIcon />
        </button>
        <div className="progress_dots">
          {new Array(dotsAmount).fill(null).map((_, index) => {
            const nthDot = skipDots + index;
            return (
              <div
                className={classNames(`progress_dot`, {
                  "-active": nthDot === currentQuestionIndex,
                  "-small":
                    (index === 0 && nthDot > 0) ||
                    (index === dotsAmount - 1 && nthDot < questions.length - 1),
                })}
                key={index}
              />
            );
          })}
        </div>
        <button
          type="button"
          className={classNames(`progress_goButton`, {
            "-forward": !isLastQustion,
            "-finish": isLastQustion,
          })}
          disabled={isEmptyAnswer(answer)}
          onClick={handleSubmit}
        >
          {isLastQustion ? <CheckIcon /> : <ArrowIcon />}
        </button>
      </div>
    </main>
  );
};

export default Quiz;
