import { useEffect, useState } from "react";

const parameters = new URLSearchParams(window.location.search);
export const token = parameters.get(`token`);
const API_URL_BASE = `https://sgmu.seapi.ru/api/v1`;

interface RequestProps {
  method: `GET` | `POST`;
  endPoint: string;
  parameters?: object;
  mayFetch?: boolean;
}

export async function makeRequest<T>({
  method,
  endPoint,
  parameters,
}: RequestProps): Promise<T | null> {
  const headers: { [header: string]: string } = {
    Authorization: `Bearer ${token}`,
  };
  if (method === `POST`) {
    headers["Content-Type"] = `application/json`;
  }

  const response = await fetch(`${API_URL_BASE}/${endPoint}`, {
    method,
    headers,
    body: parameters === undefined ? null : JSON.stringify(parameters),
  });

  try {
    const parsed: T = await response.json();
    return parsed;
  } catch {
    return null;
  }
}

function useRequest<T>({
  method,
  endPoint,
  parameters,
  mayFetch = true,
}: RequestProps): T | null {
  const [response, setResponse] = useState<T | null>(null);

  useEffect(() => {
    if (!mayFetch || response !== null) {
      return;
    }

    let isCancelled = false;

    makeRequest<T>({ method, endPoint, parameters }).then((response) => {
      if (!isCancelled) {
        setResponse(response);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, [mayFetch, method, endPoint, parameters, response]);

  return response;
}

export default useRequest;
