import "./style.scss";

import { Answer } from "../Quiz";
import * as types from "../types";
import FreeFormInput from "./FreeFormInput";
import SingleChoice from "./SingleChoice";
import MultipleChoice from "./MultipleChoice";
import { useEffect, useRef, useState } from "react";
import { useTime } from "../useTime";

interface QuestionProps {
  question: types.Question;
  answer: Answer | null;
  currentQuestion: number;
  totalQuestions: number;
  onSubmit(): void;
  onChange(answer: Answer): void;
}

const TIMEOUT_BAR_RADIUS = 24;

const useTimeout = (timeout: number | undefined, onTimeout: () => void) => {
  const onTimeoutRef = useRef(onTimeout);

  useEffect(() => {
    onTimeoutRef.current = onTimeout;
  }, [onTimeout]);

  useEffect(() => {
    if (!timeout) {
      return;
    }

    let timeoutId = setTimeout(() => onTimeoutRef.current(), timeout * 1000);
    return () => clearTimeout(timeoutId);
  }, [timeout]);

  const [startTime] = useState(Date.now);
  const currentTime = useTime({ updateEachFrame: true });

  if (timeout) {
    const timeLeft = Math.round(timeout - (currentTime - startTime) / 1000);
    const seconds = timeLeft % 60;
    const minutes = Math.floor(timeLeft / 60);

    const lastedRatio = Math.min(
      (currentTime - startTime) / (1000 * timeout),
      1,
    );
    const lastedAngle = lastedRatio * 2 * Math.PI;
    const endPointX = 2 + TIMEOUT_BAR_RADIUS * (1 + Math.sin(lastedAngle));
    const endPointY = 2 + TIMEOUT_BAR_RADIUS * (1 - Math.cos(lastedAngle));
    const chooseLongestArc = lastedRatio > 0.5;

    return { seconds, minutes, endPointX, endPointY, chooseLongestArc };
  }

  return null;
};

const Question = ({
  answer,
  question,
  onSubmit,
  onChange,
  currentQuestion,
  totalQuestions,
}: QuestionProps) => {
  const timeLeft = useTimeout(question.timeout, onSubmit);

  let input;
  switch (question.type) {
    case types.QuestionType.STRING:
    case types.QuestionType.FREE_FORM: {
      input = (
        <FreeFormInput
          type={
            question.type === types.QuestionType.STRING
              ? `oneLine`
              : `multiLine`
          }
          answer={answer as string}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      );
      break;
    }
    case types.QuestionType.SINGLE: {
      input = (
        <SingleChoice
          choice={answer as number | null}
          options={question.answers!.map((answer) => answer.text)}
          onChange={onChange}
        />
      );
      break;
    }
    case types.QuestionType.MULTIPLE: {
      input = (
        <MultipleChoice
          answer={answer as Set<number>}
          options={question.answers!.map((answer) => answer.text)}
          onChange={onChange}
        />
      );
      break;
    }
  }

  return (
    <>
      <header className="quiz_header">
        <p className="quiz_nthQuestion">
          Вопрос {currentQuestion + 1}/{totalQuestions}
        </p>
        {timeLeft !== null && (
          <svg
            viewBox="0 0 52 52"
            width="52px"
            height="52px"
            className="timeout"
          >
            <circle className="timeout_bar" cx="26px" cy="26px" r="24px" />
            <path
              className="timeout_filled"
              d={`M 26,2 A ${TIMEOUT_BAR_RADIUS} ${TIMEOUT_BAR_RADIUS} 0 ${Number(
                timeLeft.chooseLongestArc,
              )} 1 ${timeLeft.endPointX},${timeLeft.endPointY}`}
            />
            <text className="timeout_time" x="26px" y="32px">
              {String(timeLeft.minutes).padStart(2, `0`)}:
              {String(timeLeft.seconds).padStart(2, `0`)}
            </text>
          </svg>
        )}
      </header>
      <div
        className="question"
        dangerouslySetInnerHTML={{ __html: question.text }}
      />
      {input}
    </>
  );
};

export default Question;
