export interface Stage {
  title: string;
  isActive: boolean;
  answerAvailable: boolean;
  canParticipate: boolean;
  isVideoQuestion: boolean;
  isDuel: boolean;
  showStartView: boolean;
  startViewText: string | null;
  points: number | null;
}

export enum QuestionType {
  STRING = 0,
  SINGLE = 1,
  MULTIPLE = 2,
  FREE_FORM = 3,
}

export interface Question {
  id: number;
  isActive: boolean;
  title: string;
  text: string;
  type: QuestionType;
  answer: string | null;
  answers: Answer[] | null;
  timeout?: number;
}

export interface Answer {
  id: number;
  text: string;
  isAnswer: boolean;
  answer: string | null;
}

export interface SubmittedAnswer {
  questionId: number;
  answer: string;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
}
