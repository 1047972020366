import "./style.scss";

import { useCallback, useMemo, useState } from "react";
import { selectSecondsForm } from "../plurals";
import Quiz from "../Quiz";
import { StageResults, UserResult } from "../Results";
import * as types from "../types";
import useSocket from "../useSocket";
import Spinner from "../Spinner";
import { useTime } from "../useTime";

interface DuelProps {
  id: number;
  name: string;
  title: string;
  questions: types.Question[];
}

type State = `searching` | `preparing` | `started` | `finished`;

const PREPARATION_DURATION = 5 * 1000;

const Duel = ({ id, name, title, questions: rawQuestions }: DuelProps) => {
  const [state, setState] = useState<State>(`searching`);

  const [mateName, setMateName] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<number | null>(null);
  const currentTime = useTime();

  const [myResult, setMyResult] = useState<UserResult | null>(null);
  const [mateResult, setMateResult] = useState<UserResult | null>(null);

  const questions = useMemo(
    () =>
      rawQuestions
        .map((question) => ({ ...question, timeout: 15 }))
        .sort(() => Math.random() - 0.5),
    [rawQuestions],
  );

  const socket = useSocket({
    id,
    name,
    onPreparationStarted: useCallback(
      (mateName) => {
        if (state !== `searching`) {
          return;
        }
        setState(`preparing`);
        setMateName(mateName);
        setStartTime(Date.now() + PREPARATION_DURATION);
      },
      [state],
    ),
    onDuelStarted: useCallback(() => {
      if (state === `preparing`) {
        setState(`started`);
      }
    }, [state]),
    onMateResult: useCallback(({ points, time }) => {
      setMateResult({ points, time, answers: [] });
    }, []),
  });

  const handleSubmit = useCallback(
    (result: UserResult) => {
      setMyResult(result);
      socket.submitMyResult(result.points);
      setState(`finished`);
    },
    [socket],
  );

  if (state === `searching`) {
    return (
      <main className="duel">
        <p className="duel_searching">Ищем противника...</p>
        <Spinner />
      </main>
    );
  }

  if (state === `preparing`) {
    const seconds = Math.round((startTime! - currentTime) / 1000);
    return (
      <main className="duel">
        <p className="duel_preparing">
          Начинаем дуэль через {seconds} {selectSecondsForm(seconds)}...
        </p>
        <p className="duel_mateName">Противник: {mateName}</p>
        <Spinner />
      </main>
    );
  }

  if (state === `started`) {
    return <Quiz questions={questions} onSubmit={handleSubmit} />;
  }

  if (state === `finished`) {
    return (
      <StageResults
        type="duel"
        title={title}
        userId={id}
        mateName={mateName!}
        myResult={myResult!}
        mateResult={mateResult}
        maxPoints={questions.length}
      />
    );
  }

  return null;
};

export default Duel;
