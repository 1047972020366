import classNames from "classnames";
import { ReactComponent as RadioCheckedIcon } from "./icons/radioChecked.svg";
import { ReactComponent as RadioUncheckedIcon } from "./icons/radioUnchecked.svg";

interface SingleChoiceProps {
  options: string[];
  choice: number | null;
  onChange(choice: number): void;
}

const SingleChoice = ({ options, choice, onChange }: SingleChoiceProps) => {
  return (
    <div className="choice">
      {options.map((option, index) => {
        const isChecked = choice === index;
        const Icon = isChecked ? RadioCheckedIcon : RadioUncheckedIcon;

        return (
          <label
            className={classNames(`option`, { "-chosen": isChecked })}
            key={index}
          >
            <input
              type="radio"
              name="singleChoice"
              onChange={() => onChange(index)}
              checked={isChecked}
            />
            <div
              className="option_label"
              dangerouslySetInnerHTML={{ __html: option }}
            />
            <Icon className="option_icon" />
          </label>
        );
      })}
    </div>
  );
};

export default SingleChoice;
