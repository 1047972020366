import { useState } from "react";
import classNames from "classnames";

interface FreeFormInputProps {
  type: `oneLine` | `multiLine`;
  answer: string;
  onChange(answer: string): void;
  onSubmit(): void;
  label?: string;
}

const FreeFormInput = ({
  type,
  answer,
  onChange,
  onSubmit,
  label = `Ваш ответ`,
}: FreeFormInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === `Enter`) {
      onSubmit();
    }
  };

  return (
    <label className={classNames(`freeFormInput`, { "-sticky": isFocused })}>
      <span className="freeFormInput_label">{label}</span>
      {type === `oneLine` && (
        <input
          type="text"
          className="freeFormInput_input"
          value={answer}
          onChange={(event) => onChange(event.currentTarget.value)}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      )}
      {type === `multiLine` && (
        <textarea
          className="freeFormInput_input"
          onChange={(event) => onChange(event.currentTarget.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={answer}
        />
      )}
    </label>
  );
};

export default FreeFormInput;
