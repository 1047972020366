import "./style.scss";

const Rules = () => {
  return (
    <main className="rules">
      <h2>Что такое «МедКвиз»?</h2>
      <p>
        Это веселая и познавательная игра от Сибирского государственного
        медицинского университета. Хочешь проверить свои знания или блеснуть
        интеллектом? Пройди квиз и узнай уровень своей подготовки!
      </p>

      <h2>Порядок проведения «МедКвиз»</h2>
      <p>
        Викторина проводится с 18.02.2022 по 13.05.2022 и включает три этапа:
      </p>
      <ol>
        <li>отборочный;</li>
        <li>полуфинал;</li>
        <li>финал.</li>
      </ol>

      <p>
        Отборочный этап проводится с 18 февраля по 08 апреля 2022 года и состоит
        из 4-х раундов:
      </p>
      <ul>
        <li>1 раунд – 18-22 февраля с 06:00 до 20:00 (мск);</li>
        <li>2 раунд – 04-08 марта с 06:00 до 20:00 (мск);</li>
        <li>3 раунд – 18-22 марта с 06:00 до 20:00 (мск);</li>
        <li>4 раунд – 8 апреля 12:00 (мск).</li>
      </ul>

      <p>Полуфинал проводится с 15 по 22 апреля 2022 г.</p>
      <p>Финал викторины - 13 мая 2022 г.</p>
      <p>
        Итоги викторины размещаются на официальном сайте, официальных группах
        СибГМУ в социальных сетях, мобильном приложении.
      </p>
    </main>
  );
};

export default Rules;
