import "./style.scss";

import { Link } from "react-router-dom";
import { Stage } from "../types";
import { stageDestination } from "../util";

interface Props {
  stage: Stage;
}

const Introduction = ({ stage }: Props) => {
  const destination = stageDestination(stage);

  return (
    <main className="introduction">
      <p className="introduction_content">
        {stage.startViewText!}
      </p>
      <Link className="introduction_go" to={destination} replace={true}>
        Далее
      </Link>
    </main>
  );
};

export default Introduction;
