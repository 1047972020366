import classNames from "classnames";
import { ReactComponent as CheckboxCheckedIcon } from "./icons/checkboxChecked.svg";
import { ReactComponent as CheckboxUncheckedIcon } from "./icons/checkboxUnchecked.svg";

interface MultipleChoiceProps {
  options: string[];
  answer: Set<number>;
  onChange(choice: Set<number>): void;
}

const MultipleChoice = ({ options, answer, onChange }: MultipleChoiceProps) => {
  return (
    <div className="choice">
      {options.map((option, index) => {
        const isChecked = answer.has(index);
        const Icon = isChecked ? CheckboxCheckedIcon : CheckboxUncheckedIcon;

        return (
          <label
            className={classNames(`option`, { "-chosen": isChecked })}
            key={index}
          >
            <input
              type="checkbox"
              name="multipleChoice"
              onChange={() => {
                const newAnswer = new Set(answer);
                if (isChecked) {
                  newAnswer.delete(index);
                } else {
                  newAnswer.add(index);
                }
                onChange(newAnswer);
              }}
              checked={isChecked}
            />
            <div
              className="option_label"
              dangerouslySetInnerHTML={{ __html: option }}
            />
            <Icon className="option_icon" />
          </label>
        );
      })}
    </div>
  );
};

export default MultipleChoice;
