import { Question, QuestionType } from "../types";
import { Answer } from ".";
import isEqual from "lodash.isequal";

const calculatePoints = (questions: Question[], answers: Answer[]) => {
  let points = 0;

  for (const [index, question] of questions.entries()) {
    const answer = answers[index];
    switch (question.type) {
      case QuestionType.STRING: {
        const correctAnswer = question
          .answer!.trim()
          .toLowerCase()
          .replace(/\s{2,}/i, ` `);
        const normalizedSubmission = (answer as string)
          .trim()
          .toLowerCase()
          .replace(/\s{2,}/i, ` `);

        if (correctAnswer === normalizedSubmission) {
          points += 1;
        }

        break;
      }
      case QuestionType.SINGLE: {
        const correctAnswer = question.answers!.findIndex(
          (answer) => answer.isAnswer,
        );

        if (correctAnswer === answer) {
          points += 1;
        }

        break;
      }
      case QuestionType.MULTIPLE: {
        const correctAnswers = new Set();

        for (const [index, { isAnswer }] of question.answers!.entries()) {
          if (isAnswer) {
            correctAnswers.add(index);
          }
        }

        if (isEqual(correctAnswers, answer)) {
          points += 1;
        }

        break;
      }
      case QuestionType.FREE_FORM: {
        // Свободный ответ — ответ проверяется человеком
        break;
      }
    }
  }

  return points;
};

export default calculatePoints;
