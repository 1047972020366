import { StrictMode } from "react";
import ReactDom from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { token } from "./requests";

if (typeof token === `string`) {
  ReactDom.render(
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>,
    document.getElementById(`root`),
  );
} else {
  document.body.innerHTML = `<p class="signIn">Чтобы принять участие в квизе, войдите в свой аккаунт в приложении</p>`;
}
