import { Stage } from "./types";

export const stageDestination = (stage: Stage) => {
  if (stage.isVideoQuestion) {
    return `/askQuestion`;
  }

  if (stage.isDuel) {
    return `/duel`;
  }

  return `/quiz`;
};
