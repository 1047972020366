import { useCallback, useEffect, useMemo, useState } from "react";
import { io, Socket as SocketIo } from "socket.io-client";

interface Result {
  points: number;
  time: number;
}

interface UseSocketProps {
  id: number;
  name: string;
  onPreparationStarted(mateName: string): void;
  onDuelStarted(): void;
  onMateResult(result: Result): void;
}

export interface Socket {
  submitMyResult(points: number): void;
}

const useListenToEvent = (
  socket: SocketIo,
  event: string,
  listener: (...args: any[]) => void,
) => {
  useEffect(() => {
    socket.on(event, listener);
    return () => {
      socket.off(event, listener);
    };
  }, [socket, event, listener]);
};

const useSocket = ({
  id,
  name,
  onPreparationStarted,
  onDuelStarted,
  onMateResult,
}: UseSocketProps): Socket => {
  const [socket] = useState(io);

  useListenToEvent(socket, `preparationStarted`, onPreparationStarted);
  useListenToEvent(socket, `duelStarted`, onDuelStarted);
  useListenToEvent(socket, `mateResult`, onMateResult);

  const connectListener = useCallback(() => {
    socket.emit(`myInfo`, { id, name });
  }, [socket, id, name]);
  useListenToEvent(socket, `connect`, connectListener);

  const submitMyResult = useCallback(
    (points: number) => {
      socket.emit(`submission`, points);
    },
    [socket],
  );

  const socketObject = useMemo(() => ({ submitMyResult }), [submitMyResult]);

  return socketObject;
};

export default useSocket;
