import { formatDuration } from "./formatDuration";
import { ReactComponent as BonusIcon } from "./bonus.svg";
import classNames from "classnames";
import { CSSProperties } from "react";

export interface Record {
  points: number;
  time: number;
  user: {
    name: string | null;
    id: number;
  };
}

interface StartPlaceCSS extends CSSProperties {
  "--startPlace": number;
}

interface RatingProps {
  records: Record[];
  title: string;
  startPlace?: number;
  hasBonus?: boolean;
  userId: number;
}

const Rating = ({
  records,
  title,
  startPlace = 1,
  hasBonus = false,
  userId,
}: RatingProps) => {
  return (
    <section className="rating">
      <header className="rating_header">
        <h2 className="rating_title">{title}</h2>
        <p className="rating_places">
          Места {startPlace}–{records.length + startPlace - 1}
        </p>
        {hasBonus && (
          <div className="rating_bonus">
            +1 <BonusIcon />
          </div>
        )}
      </header>
      <ol
        className="rating_records"
        style={{ "--startPlace": startPlace - 1 } as StartPlaceCSS}
      >
        {records.map((record, index) => {
          const isMe = userId === record.user.id;
          return (
            <li className={classNames(`record`, { "-me": isMe })} key={index}>
              <p className="record_name">
                {isMe ? `Вы` : record.user.name ?? ``}
              </p>
              <p className="record_points">{record.points}</p>
              <p className="record_time">{formatDuration(record.time)}</p>
            </li>
          );
        })}
      </ol>
    </section>
  );
};

export default Rating;
