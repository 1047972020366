import "./style.scss";

import { useState } from "react";
import FreeFormInput from "../Question/FreeFormInput";
import { ReactComponent as Illustration } from "./illustration.svg";
import { makeRequest } from "../requests";

interface QuestionFormProps {
  onSubmit(link: string): void;
}

const QuestionForm = ({ onSubmit }: QuestionFormProps) => {
  const [link, setLink] = useState(``);

  const handleSubmit = () => onSubmit(link);

  return (
    <main className="askQuestion">
      <ul className="askQuestion_rules">
        <li className="askQuestion_rule">
          <h2>Снимите видео-вопрос</h2>
          <p>Придумайте свой вопрос и запишите его на видео.</p>
        </li>
        <li className="askQuestion_rule">
          <h2>Отправьте ссылку</h2>
          <p>До 28 марта вставьте ссылку на видео в форме ниже.</p>
        </li>
        <li className="askQuestion_rule">
          <h2>Дождитесь результата</h2>
          <p>
            Оргкомитет выберет 12 лучших видео-вопросов и задаст
            их преподавателям СибГМУ. За каждого преподавателя, не ответившего
            на вопрос, его автору начисляется балл в MedQuiz.
          </p>
        </li>
      </ul>
      <form
        className="askQuestion_form"
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <FreeFormInput
          type="oneLine"
          answer={link}
          onChange={setLink}
          onSubmit={handleSubmit}
          label="Ссылка на видео"
        />
        <button
          className="askQuestion_submit"
          type="submit"
          disabled={link.trim() === ``}
        >
          Отправить вопрос
        </button>
      </form>
    </main>
  );
};

interface SubmissionDoneProps {
  onClose(): void;
}

const SubmissionDone = ({ onClose }: SubmissionDoneProps) => {
  return (
    <main className="submissionDone">
      <div className="submissionDone_message">
        <Illustration />
        <p>
          Ваш вопрос отправлен! Вернитесь в приложение 9 апреля, чтобы узнать
          результат.
        </p>
      </div>
      <button className="submissionDone_goBack" type="button" onClick={onClose}>
        Понятно
      </button>
    </main>
  );
};

interface AskQuestionProps {
  questionId: number;
  onClose(): void;
}

const AskQuestion = ({ questionId, onClose }: AskQuestionProps) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleSubmit = (link: string) => {
    setHasSubmitted(true);
    makeRequest({
      method: `POST`,
      endPoint: `Quiz/Results`,
      parameters: {
        points: 0,
        time: 0,
        answers: [{ questionId, answer: link }],
      },
    });
  };

  if (hasSubmitted) {
    return <SubmissionDone onClose={onClose} />;
  }

  return <QuestionForm onSubmit={handleSubmit} />;
};

export default AskQuestion;
